@mixin button($bgcolor, $color, $hoverbgcolor, $activebgcolor) {
  background: $bgcolor;
  height: 40px;
  border-radius: 3px;
  font-family: AzoSans-Medium;
  font-size: 14px;
  font-weight: 500;
  color: $color;
  outline: none;
  border: none;
  padding: 0px 12px;
  &:hover {
    background: $hoverbgcolor;
    transition: all 0.3s ease;
  }
  &:active {
    background: $activebgcolor;
  }
  &[disabled] {
    background-color: rgba($bgcolor, 60%);
  }
}
