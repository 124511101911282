@import 'styles/_variables';
input[type='number'].dc_input,
input[type='text'].dc_input {
  padding: 6px 4px;
  border-radius: 3px;
  border: solid 1px #d6dce5;
  background: transparent;
  outline: none;
  font-family: 'AzoSans-Light';
  font-size: 14px;
  &.ng-touched.ng-invalid {
    box-shadow: 0 0 0 0.1rem rgba(#d70000, 0.5);
    // border: 1px solid #c70000;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(black, 0.5);
  }
  & + .dc_input_field_message {
    height: 13px;
  }
}

.dc_label {
  display: flex;
  margin-bottom: 8px;
  text-align: left;
  font-family: AzoSans-Medium;
  font-size: 14px;
  color: $primary-text-color;
  &.required::after {
    content: '*';
    color: $primary-color;
    display: block;
  }
}

.dc_input_wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
