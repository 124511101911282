@import '../node_modules/ag-grid-community/src/styles/ag-grid.scss';
@import '../node_modules/ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss';

/* You can add global styles to this file, and also import other style files */
.ag-theme-alpine {
  @include ag-theme-alpine();
}

@import 'buttons';
@import 'inputs';
@import '../node_modules/@swimlane/ngx-datatable/themes/material.scss';
@import '../node_modules/@swimlane/ngx-datatable/themes/bootstrap.scss';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';
@import '~@fortawesome/fontawesome-free/css/all.css';
/* $fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss'; */

@font-face {
  font-family: 'AzoSans-Regular';
  src: url('assets/fonts/AzoSans/OpenType/AzoSans-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AzoSans-Light';
  src: url('assets/fonts/AzoSans/OpenType/AzoSans-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AzoSans-LightItalic';
  src: url('assets/fonts/AzoSans/OpenType/AzoSans-LightItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'AzoSans-Italic';
  src: url('assets/fonts/AzoSans/OpenType/AzoSans-Italic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'AzoSans-Medium';
  src: url('assets/fonts/AzoSans/OpenType/AzoSans-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'AzoSans-MediumItalic';
  src: url('assets/fonts/AzoSans/OpenType/AzoSans-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'AzoSans-Bold';
  src: url('assets/fonts/AzoSans/OpenType/AzoSans-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'AzoSans-BoldItalic';
  src: url('assets/fonts/AzoSans/OpenType/AzoSans-BoldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'AzoSans-Thin';
  src: url('assets/fonts/AzoSans/OpenType/AzoSans-Thin.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AzoSans-ThinItalic';
  src: url('assets/fonts/AzoSans/OpenType/AzoSans-ThinItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

html,
body {
  font-family: 'AzoSans-Light', sans-serif !important;
  margin: 0;
  padding: 0;
  height: 100%;
}

.row {
  margin-right: 0;
}

/*Pie chart customization*/
/*tooltip styles cant be included in component css as it is rendered outside the body*/
.nvtooltip-re {
  color: #ffffff;
  padding: 2px 4px;
  font-family: AzoSans-Regular;
  font-size: 11px;
  border: 1px solid #485465;
  border-radius: 4px;
  background: #485465;
}

.nvtooltip-re table {
  margin: 2px;
}

.nvtooltip-re table td.legend-color-guide div {
  width: 8px;
  height: 8px;
  border-radius: 20px;
}

.nvtooltip-re table td.value {
  display: none;
}

.tooltip {
  white-space: normal;
  max-width: 100%;

  .tooltip-inner {
    text-align: left;
    max-width: 100%;

    p:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
}

.noData {
  text-align: center;
  height: 400px;
  margin: 140px auto;
  font-size: 18px;
  font-family: Azosans-Medium;
  color: #000;
}

.ag-tooltip {
  background-color: #202b20;
  color: #fff;
  border-radius: 2px;
  padding: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #545454;
  font-size: 12px;
}

.disable-access {
  cursor: not-allowed;
  pointer-events: none;
}

/* width */
::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

$white: #fff;
$black: #000;
$chart-tooltip-border-radius: 5px;
$chart-caret-size: 7px solid $white !default;

//Add application styles & imports to this file!
@import '~@angular/material/theming';
@include mat-core();
$primary: mat-palette($mat-indigo);
$accent: mat-palette($mat-pink, A200, A100, A400);
$theme: mat-light-theme((color: (primary: $primary,
        accent: $accent,
      ),
    ));

@mixin charts-tooltip($theme) {
  $typography: mat-typography-config();
  $foreground: map-get($theme, foreground);

  .chart__tooltip-sub-label {
    color: mat-color($foreground, secondary-text);
    font-size: mat-font-size($typography, body-2);
  }

  .ngx-charts-tooltip-content.type-tooltip {
    @include mat-elevation(3);
    background-color: #fff !important;
    border-radius: 5px !important;
    color: #000 !important;
    padding: 8px !important;
    //position: absolute;
    font-family: Arial, sans-serif;
    font-size: 13px;
    text-align: left;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background: hsla(0, 0%, 100%, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 4px;

    table {
      strong.x-value {
        font-weight: 700;
      }

      td {
        padding: 2px 9px 2px 0;
        vertical-align: middle;
      }

      td.legend-color-guide div {
        vertical-align: middle;
        width: 12px;
        height: 12px;
        border: 1px solid #999;
      }

      td.key {
        font-weight: 400;
      }

      td.value {
        text-align: right;
        font-weight: 700;
      }
    }
  }

  /* .ngx-charts-tooltip-content.type-tooltip {
    background-color: $white !important;
    color: $black !important;
  } */

  .tooltip-caret {
    display: none;
  }

  /* .ngx-charts-tooltip-content.type-tooltip .tooltip-caret.position-top {
    border-top: $chart-caret-size !important;
  }

  .ngx-charts-tooltip-content.type-tooltip .tooltip-caret.position-right {
    border-right: $chart-caret-size !important;
  }

  .ngx-charts-tooltip-content.type-tooltip .tooltip-caret.position-bottom {
    border-bottom: $chart-caret-size !important;
  }

  .ngx-charts-tooltip-content.type-tooltip .tooltip-caret.position-left {
    border-left: $chart-caret-size !important;
  }*/
}

@include angular-material-theme($theme);
@include charts-tooltip($theme);

.chart-legend {
  .legend-labels {
    background: #fff !important;
    position: absolute;
    width: 90% !important;

  }

  .legend-wrap {
    .horizontal-legend {
      display: flex;
      justify-content: flex-end;
    }

    li {
      .legend-label-text {
        font-size: 10px !important;
        color: #4a4a4a !important;
      }
    }
  }
}

.transparentbg {
  height: 100vh !important;
  width: 100vw !important;
  top: 0 !important;
  left: 0 !important;
}

.mat-error {
  font-family: 'AzoSans-Medium';
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #d70000;
  min-width: 290px;
  display: inline !important;
}


.headBlock {
  border-bottom: 1px dashed #ccc;
  margin-bottom: 5px;
}

.p-0 {
  //padding: 0;
}

.p-r-0 {
  padding-right: 0 !important;
}

.p-l-0 {
  padding-left: 0 !important;
}

.m-r-0 {
  margin-right: 0 !important;
}

.m-l-0 {
  margin-left: 0 !important;
}

.m-all-0 {
  margin: 0 !important;
}

.frt {
  float: right !important;
}

.flt {
  float: left !important;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: start;
}

.align-items-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.form-control.dropDownList.bdrLeftRed {
  border-left: 8px solid red;
}

.form-control.dropDownList.bdrLeftAmber {
  border-left: 8px solid #efc035;
}

.form-control.dropDownList.bdrLeftGreen {
  border-left: 8px solid green;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.multiselect-dropdown {
  .dropdown-btn {
    display: flex !important;
    justify-content: start;
    align-items: center;

    .dropdown-multiselect__caret {
      height: 100% !important;
    }

    span:last-of-type {
      color: white;
    }
  }
}

/*---------POPUP WINDOW ISSUE FIX-----*/
.transparentbg {
  background: #003d5cbd !important;
  position: fixed !important;
  // z-index: 9 !important;
}

.whitebg {
  top: 80px !important;
  left: 0 !important;
  right: 0 !important;
  min-height: 200px !important;
  position: fixed !important;
  transform: unset !important;
  z-index: 12 !important;
  margin: 0 auto !important;
}

/*---------END POPUP WINDOW ISSUE FIX-----*/