@import 'styles/_mixins';
@import 'styles/_variables';
.primary-btn {
  @include button($primary-color, $primar-button-color, darken($primary-color, 8%), darken($primary-color, 10%));
}

.secondary-btn {
  @include button(white, $primary-text-color, rgba($primary-color, 8%), rgba($primary-color, 10%));
  border: 1px solid $primary-color;
}
